import React from "react";
import { Modal } from "react-bootstrap";
import { FaHeart } from "react-icons/fa";
import { Buffer } from "buffer";
import { Link } from "react-router-dom";
import UserImage from '../../../asstes/Group 51.svg'

import LikedIcon from "../../../asstes/icons/Liked.svg";


import  "./strategyList.css"
const StrategyLikeList = (props) => {
  const { show, handleClose, allLikedUser } = props;
  return (
    <Modal
      className="userLikeList"
      style={{ overflowY: "auto" }}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h4 className="" id="">
            Liked By{" "} <img src={LikedIcon}  width="32px" height="32px" alt="likeIcon"/>
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowY: "auto" }}>
        {allLikedUser && allLikedUser?.map((e, i) => (
          <div className="userName" key={i} >          
                        {
                          e.image ?
                            <img width="38px" height="38px" className='label mr-1' style={{ borderRadius: '1000px' }} src={`data:${e?.image?.contentType};base64,${Buffer.from(e?.image?.data?.data).toString('base64')}`} alt="" />
                            :
                            <img width="38px" height="38px" src={UserImage} alt="person pic" className="mr-1"/>
                        }
          <Link className="" to={`/profile/${e?._id}`}>
            {e?.firstName} {e?.lastName}
          </Link>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};

export default StrategyLikeList;
