import React from 'react';
import SearchScrean from '../Components/SearchScrean/SearchScrean';

const Stratigy = () => {
    return (
        <>
            <SearchScrean />
        </>
    );
};

export default Stratigy;