import React from 'react';
import { Modal } from 'react-bootstrap';
import { Buffer } from 'buffer';
import SavedIcon from "../../../asstes/icons/Saved.svg";
import UserImage from '../../../asstes/Group 51.svg'
import { Link } from 'react-router-dom';
import  "./strategyList.css"

const StrategyFavList = (props) => {
  const { show, handleClose, allSavesUser } = props;
  return (
    <Modal className='userLikeList' style={{ overflowY: 'auto' }} show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4 className='' id="">Saved By <img src={SavedIcon}  width="28px" height="28px" alt="likeIcon"/></h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowY: "auto" }}>
      {allSavesUser && allSavesUser?.map((e,i)=>(
        <div className="userName" key={i}>
             {
                          e.image ?
                            <img width="38px" height="38px" className='label mr-1' style={{ borderRadius: '1000px' }} src={`data:${e?.image?.contentType};base64,${Buffer.from(e?.image?.data?.data).toString('base64')}`} alt="" />
                            :
                            <img width="38px" height="38px" src={UserImage} alt="person pic" className="mr-1"/>
                        }
        <Link to={`/profile/${e?._id}`}>{e?.firstName}{e?.lastName}</Link>
        </div>
      ))}
      
      
      </Modal.Body>
    </Modal>
  );
};

export default StrategyFavList;