import React from 'react'
import "./styles/staticPage.css"

const TermsConditions = () => {
  return (
<div className="WordSection1 terms_conditions">
          <p className="MsoNormal"><b><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Terms and Conditions</span></b></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>This
              document is an electronic record in terms of the Information Technology Act,
              2000 and rules thereunder as applicable and the amended provisions pertaining
              to electronic records in various statutes as amended by the Information
              Technology Act, 2000. This electronic record is generated by a computer system
              and does not require any physical or digital signatures.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>This
              document is published in accordance with the provisions of Rule 3 (1) of the
              Information Technology (Intermediaries guidelines) Rules, 2011 that require
              publishing the rules and regulations, privacy policy and Terms of Use for
              access or usage of </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> website.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>The
              domain name </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school &nbsp;</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>
              (hereinafter referred to as "Website") is owned by Things Education
              LLP which has its registered office at 106 Dhananjay Society, Near Akota
              Stadium, Akota, Vadodara - 390020 (hereinafter referred to as “owner” or “the
              owner”).</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Your
              use of the Website and services and tools are governed by the following terms
              and conditions (“Terms of Use”) as applicable to the Website including the
              applicable policies which are incorporated herein by way of reference. If You
              transact on the Website, You shall be subject to the policies that are
              applicable to the Website for such transaction. By mere use of the Website, You
              shall be contracting with the owner and these terms and conditions, including
              the policies, constitute Your binding obligations, with the owner.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>For
              the purpose of these Terms of Use, wherever the context so requires
              "You" or “User” shall mean any natural or legal person who has agreed
              to become a subscriber on the Website by providing Registration Data while
              registering on the Website as Registered User using the computer systems. The
              owner allows the User to surf the Website or making purchases without
              registering on the Website. The term “We”, “Us”, “Our” shall mean the owner.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>When
              You use any of the services provided by Us through the Website, including but
              not limited to searching for teaching strategies; commenting on, liking, saving
              or editing teaching strategies; converting teaching strategies to lesson plans;
              and accessing prior knowledge, misconceptions and check for understanding
              questions for a teaching strategy, You will be subject to the rules,
              guidelines, policies, terms, and conditions applicable to such service, and
              they shall be deemed to be incorporated into this Terms of Use and shall be
              considered as part and parcel of this Terms of Use. We reserve the right, at
              Our sole discretion, to change, modify, add or remove portions of these Terms
              of Use, at any time without any prior written notice to You. It is Your
              responsibility to review these Terms of Use periodically for updates / changes.
              Your continued use of the Website following the posting of changes will mean
              that You accept and agree to the revisions. As long as You comply with these
              Terms of Use, We grant You a personal, non-exclusive, non-transferable, limited
              privilege to enter and use the Website.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>ACCESSING,
              BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS
              AND CONDITIONS UNDER THESE TERMS OF USE, SO PLEASE READ THE TERMS OF USE
              CAREFULLY BEFORE PROCEEDING. By impliedly or expressly accepting these Terms of
              Use, You also accept and agree to be bound by the owner’s Policies ((including
              but not limited to Privacy Policy available on <a target="_blank" href="/privacy-policy" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> privacy policy</a>) as amended from time to time.</span></p>
          <p className="MsoNormal"><b><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Services overview</span></b></p>
          <p className="MsoNormal"><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school&nbsp;</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>
              provides a platform for educators to access teaching strategies for various
              subjects for grades from preschool to 10. The users are expected to register
              with the website to access all the features of the site. Users can search for
              the best teaching strategies, modify them, save them, create new strategies and
              share with other users. Users can convert teaching strategies into lesson
              plans, get check-for-understanding questions, misconceptions and prior
              knowledge for each teaching strategy. The website will provide a community for
              educators who can interact with each other through the comments section of the
              website and can visit each other’s profiles.</span></p>
          <p className="MsoNormal"><b><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Eligibility</span></b></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Use
              of the Site is available only to persons who can form legally binding contracts
              under Indian Contract Act, 1872. Persons who are "incompetent to
              contract" within the meaning of the Indian Contract Act, 1872 including
              minors, un-discharged insolvents etc. are not eligible to use the Website. If
              you are a minor i.e. under the age of 18 years, you shall not register as a
              User of the Site and shall not transact on or use the website. As a minor if
              you wish to use or transact on website, such use or transaction may be made by
              your legal guardian or parents on the Site. The owner reserves the right to
              terminate your membership and / or refuse to provide you with access to the
              Website if it is brought to the owner notice or if it is discovered that you
              are under the age of 18 years.</span></p>
          <p className="MsoNormal"><b><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Communications</span></b></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>When
              You use the Website or send emails or other data, information or communication
              to us, You agree and understand that You are communicating with Us through
              electronic records and You consent to receive communications via electronic
              records from Us periodically and as and when required. We may communicate with
              you by email or by such other mode of communication, electronic or otherwise.</span></p>
          <p className="MsoNormal"><b><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>License and site access</span></b></p>
          <p className="MsoNormal"><a name="_Hlk151729749"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Things Education LLP and the site
                owned by it, that is </span></a><a target="_blank" href="https://teps.school"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> grants
              you a limited license to access and make personal use of this site and not to
              download (other than page caching) or modify it, or any portion of it, except
              with express written consent of the owner. This license does not include any
              resale or commercial use of this site or its contents; any collection and use
              of any product listings, descriptions, or prices; any derivative use of this
              site or its contents; any downloading or copying of account information for the
              benefit of another merchant; or any use of data mining, robots, or similar data
              gathering and extraction tools. This site or any portion of this site may not
              be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited
              for any commercial purpose without express written consent of the owner. You
              may not frame or utilize framing techniques to enclose any trademark, logo, or
              other proprietary information (including images, text, page layout, or form) of &nbsp;
            </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> and
              our affiliates without express written consent. You may not use any meta tags
              or any other "hidden text" utilizing </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>’s name
              or trademarks without the express written consent of the owner. Any
              unauthorized use terminates the permission or license granted by the owner.</span></p>
          <p className="MsoNormal"><b><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Disclaimer</span></b></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Pricing
              on any service(s) as is reflected on the Website may be incorrect due to some
              technical issue, typographical error or product information incorrectly
              reflected and in such an event We may cancel such your order(s).</span></p>
          <p className="MsoNormal"><b><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Privacy</span></b></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>We
              view protection of Your privacy as a very important principle. We understand
              clearly that You and Your Personal Information is one of Our most important assets.
              We store and process Your Information including any sensitive financial
              information collected (as defined under the Information Technology Act, 2000),
              if any, on computers that may be protected by physical as well as reasonable
              technological security measures and procedures in accordance with Information Technology
              Act 2000 and Rules there under. Our current Privacy Policy is available at <a target="_blank" href="/privacy-policy" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> privacy policy</a>.</span></p>
          <p className="MsoNormal"><b><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Payment</span></b></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>While
              availing any of the payment method/s available on the Website, we will not be
              responsible or assume any liability, whatsoever in respect of any loss or
              damage arising directly or indirectly to You due to:</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Lack
              of authorization for any transaction/s, or</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Exceeding
              the preset limit mutually agreed by You and between "Bank/s", or</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Any
              payment issues arising out of the transaction, or</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Decline
              of transaction for any other reason/s</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>All
              payments made against the purchases/services on Website by you shall be
              compulsorily in Indian Rupees acceptable in the Republic of India. The Website
              will not facilitate transaction with respect to any other form of currency with
              respect to the purchases made on Website.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Before
              activating your subscription plan, we may request you to provide supporting
              documents (including but not limited to Govt. issued ID and address proof) to
              establish the ownership of the payment instrument used by you for your
              purchase. This is done in the interest of providing a safe online shopping
              environment to Our Users.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>You
              will inform the owner about non-activation of the subscription plan within 5
              days of date of activation informed to you. The owner will then follow up on
              the reason for non-activation.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Detailed
              terms on the Payment Terms<a target="_blank" href="/payment-terms" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> payment terms</a> page.</span></p>
          <p className="MsoNormal"><b><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>You Agree and Confirm</span></b></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>That
              in the event of non-activation of subscription plan/s on account of a mistake
              by you (i.e. wrong name or address or any other wrong information) any extra
              cost incurred by the owner for activation shall be bourne from you.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>That
              you will use the services provided by the owner, its affiliates, consultants
              and contracted companies, for lawful purposes only and comply with all
              applicable laws and regulations while using the Website and transacting on the
              Site.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>You
              will provide authentic and true information in all instances where such
              information is requested of you. The owner reserves the right to confirm and
              validate the information and other details provided by you at any point of
              time. If upon confirmation your details are found not to be true (wholly or
              partly), we have the right in its sole discretion to reject the registration
              and debar you from using the Services and / or other affiliated websites
              without prior intimation whatsoever.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>That
              you are accessing the services available on this Website and transacting at
              your sole risk and are using your best and prudent judgment before entering
              into any transaction through this Website.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>That
              the details for registration and activation of subscription plan provided by
              you will be correct and proper in all respects.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>That
              before making a payment for a subscription, you will check the services
              description carefully. By making a payment for a subscription plan, you agree
              to be bound by the conditions of sale included in the service’s description.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>You
              may not use the Website for any of the following purposes:</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Disseminating
              any unlawful, harassing, libelous, abusive, threatening, harmful, vulgar,
              obscene, or otherwise objectionable material.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Transmitting
              material that encourages conduct that constitutes a criminal offence results in
              civil liability or otherwise breaches any relevant laws, regulations or code of
              practice.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Gaining
              unauthorized access to other computer systems.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Interfering
              with any other person's use or enjoyment of the Site.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Breaching
              any applicable laws;</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Interfering
              or disrupting networks or web sites connected to the Site.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Making,
              transmitting or storing electronic copies of materials protected by copyright
              without the permission of the owner.</span></p>
          <p className="MsoNormal"><b><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Colours and Size</span></b></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>We
              have made every effort to display the colours and size of our products that
              appear on the Website as accurately as possible. However, as the actual colours
              you see will depend on your monitor and other technical constraints. We cannot
              guarantee that your monitor's display of any colour will be accurate.</span></p>
          <p className="MsoNormal"><b><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Modification of Terms &amp; Conditions of Service</span></b></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Things
              Education LLP and the site owned by it, that is </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span className="MsoHyperlink"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif', textDecoration: 'none'}}> </span></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>may at any time modify the Terms of Use of
              the Website without any prior notification to you. You can access the latest
              version of these Terms at any given time on </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>. You
              should regularly review the Terms on </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>. In
              the event the modified Terms are not acceptable to you, you should discontinue
              using the Service. However, if you continue to use the Service you shall be
              deemed to have agreed to accept and abide by the modified Terms of Use of this
              site.</span></p>
          <p className="MsoNormal"><b><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Governing Law and Jurisdiction</span></b></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>This
              User Agreement shall be construed in accordance with the applicable laws of
              India. The Courts at Vadodara shall have exclusive jurisdiction in any
              proceedings arising out of this agreement.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Any
              dispute or difference either in interpretation or otherwise, of any terms of
              this User Agreement between the parties hereto, the same shall be referred to
              an independent arbitrator who will be appointed by the owner. and his decision
              shall be final and binding on the parties hereto. The above arbitration shall
              be in accordance with the Arbitration and Conciliation Act, 1996 as amended
              from time to time. The arbitration shall be held in Vadodara. The High Court of
              judicature at Vadodara alone shall have the jurisdiction and the Laws of India
              shall apply.</span></p>
          <p className="MsoNormal"><b><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Reviews, Feedback, Submissions</span></b></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>All
              reviews, comments, feedback, postcards, suggestions, ideas, and other
              submissions disclosed, submitted or offered to </span><a name="_Hlk151730322" /><a target="_blank" href="https://teps.school"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a><span className="MsoHyperlink"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif', textDecoration: 'none'}}> </span></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>on or by this Website or otherwise
              disclosed, submitted or offered in connection with your use of this Website
              (collectively, the "Comments") shall be and remain our property. Such
              disclosure, submission or offer of any Comments shall constitute an assignment
              to Things Education LLP of all worldwide rights, titles and interests in all
              copyrights and other intellectual properties in the Comments. Thus, Things
              Education LLP owns exclusively all such rights, titles and interests and shall
              not be limited in any way in its use, commercial or otherwise, of any Comments.
            </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> will
              be entitled to use, reproduce, disclose, modify, adapt, create derivative works
              from, publish, display and distribute any Comments you submit for any purpose
              whatsoever, without restriction and without compensating you in any way. The
              owner is and shall be under no obligation (1) to maintain any Comments in
              confidence; (2) to pay you any compensation for any Comments; or (3) to respond
              to any Comments. You agree that any Comments submitted by you to the Website
              will not violate this policy or any right of any third party, including
              copyright, trademark, privacy or other personal or proprietary right(s), and
              will not cause injury to any person or entity. You further agree that no
              Comments submitted by you to the Website will be or contain libelous or
              otherwise unlawful, threatening, abusive or obscene material, or contain
              software viruses, political campaigning, commercial solicitation, chain
              letters, mass mailings or any form of "spam". The owner does not
              regularly review posted Comments, but does reserve the right (but not the
              obligation) to monitor and edit or remove any Comments submitted to the Site.
              You grant the owner the right to use the name that you submit in connection
              with any Comments. You agree not to use a false email address, impersonate any
              person or entity, or otherwise mislead as to the origin of any Comments you
              submit. You are and shall remain solely responsible for the content of any
              Comments you make and you agree to indemnify Things Education LLP and the site
              owned by it, that is </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> and
              its affiliates for all claims resulting from any Comments you submit. </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> and
              its affiliates take no responsibility and assume no liability for any Comments
              submitted by you or any third party. </span></p>
          <p className="MsoNormal"><b><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Copyright &amp; Trademark</span></b></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Things
              Education LLP and the site owned by it, that is </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> and
              its suppliers and licensors expressly reserve all intellectual property rights
              in all text, programs, products, processes, technology, content and other
              materials, which appear on this Site. Access to this Website does not confer
              and shall not be considered as conferring upon anyone any license under any of </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> or any
              third party's intellectual property rights. You may not modify, distribute or
              re-post anything on this website for any purpose.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>The
            </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> names
              and logos and all related product and service names, design marks and slogans
              are the trademarks or service marks of the owner All other marks are the
              property of their respective companies. No trademark or service mark license is
              granted in connection with the materials contained on this Site. Access to this
              Website does not authorize anyone to use any name, logo or mark in any manner.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>References
              on this Website to any names, marks, products or services of third parties or
              hypertext links to third party sites or information are provided solely as a
              convenience to you and do not in any way constitute or imply </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>
              endorsement, sponsorship or recommendation of the third party, information,
              product or service.</span></p>
          <p className="MsoNormal"><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> is not
              responsible for the content of any third party sites and does not make any
              representations regarding the content or accuracy of material on such sites. If
              you decide to link to any such third party websites, you do so entirely at your
              own risk.</span></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>All
              materials, including images, text, illustrations, designs, icons, photographs,
              programs, music clips or downloads, video clips and written and other materials
              that are part of this Website (collectively, the "Contents") are
              intended solely for personal, non-commercial use. You may download or copy the
              Contents and other downloadable materials displayed on the Website for your
              personal use only. No right, title or interest in any downloaded materials or
              software is transferred to you as a result of any such downloading or copying.
              You may not reproduce (except as noted above), publish, transmit, distribute,
              display, modify, create derivative works from, sell or participate in any sale
              of or exploit in any way, in whole or in part, any of the Contents, the Website
              or any related software. All software used on this Website is the property of the
              owner or their suppliers and protected by Indian and international copyright
              laws. The Contents and software on this Website may be used only as a teaching
              resource. Any other use, including the reproduction, modification,
              distribution, transmission, republication, display, or performance, of the
              Contents on this Website is strictly prohibited. Unless otherwise noted, all
              Contents are copyrights, trademarks, trade dress and/or other intellectual
              property owned, controlled or licensed by Things Education LLP and the site
              owned by it, that is </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>, one
              of its affiliates or by third parties who have licensed their materials to Things
              Education LLP and the site owned by it, that is </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> and
              are protected by Indian and international copyright laws. The compilation
              (meaning the collection, arrangement, and assembly) of all Contents on this
              Website is the exclusive property of </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> and is
              also protected by Indian and international copyright laws.</span></p>
          <p className="MsoNormal"><b><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Objectionable Material</span></b></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>You
              understand that by using this Website or any services provided on the Site, you
              may encounter Content that may be deemed by some to be offensive, indecent, or
              objectionable, which Content may or may not be identified as such. You agree to
              use the Website and any service at your sole risk and that to the fullest
              extent permitted under applicable law, </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> and
              its affiliates shall have no liability to you for Content that may be deemed
              offensive, indecent, or objectionable to you.</span></p>
          <p className="MsoNormal"><b><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Indemnity</span></b></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>You
              agree to defend, indemnify and hold harmless </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>, its
              employees, directors, officers, agents and their successors and assigns from
              and against any and all claims, liabilities, damages, losses, costs and
              expenses, including attorney's fees, caused by or arising out of claims based
              upon your actions or inactions, which may result in any loss or liability to </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> or any
              third party including but not limited to breach of any warranties,
              representations or undertakings or in relation to the non-fulfilment of any of
              your obligations under this User Agreement or arising out of the your violation
              of any applicable laws, regulations including but not limited to Intellectual
              Property Rights, payment of statutory dues and taxes, claim of libel,
              defamation, violation of rights of privacy or publicity, loss of service by
              other subscribers and infringement of intellectual property or other rights.
              This clause shall survive the expiry or termination of this User Agreement.</span></p>
          <p className="MsoNormal"><b><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Termination</span></b></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>This
              User Agreement is effective unless and until terminated by either you or </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>. You
              may terminate this User Agreement at any time, provided that you discontinue
              any further use of this Site. </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> may
              terminate this User Agreement at any time and may do so immediately without
              notice, and accordingly deny you access to the Site, Such termination will be
              without any liability to </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>. Upon
              any termination of the User Agreement by either you or </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>, you
              must promptly destroy all materials downloaded or otherwise obtained from this
              Site, as well as all copies of such materials, whether made under the User
              Agreement or otherwise. </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>'s
              right to any Comments shall survive any termination of this User Agreement. Any
              such termination of the User Agreement shall not cancel your obligation to pay
              for the product already ordered from the Website or affect any liability that
              may have arisen under the User Agreement.</span></p>
          <p className="MsoNormal"><b><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>Offers and Discounts</span></b></p>
          <p className="MsoNormal"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>For
              promotion purposes </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span className="MsoHyperlink"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif', textDecoration: 'none'}}> </span></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>shall run various discounts, offers, combo
              deals and other promotional deals. Any offer/discount code running on site
              cannot be clubbed with any other offer/discount. We reserve the right to
              withdraw the offer at any time point of time. We will not be liable to
              compensate the customer for the same once the offer is withdrawn. Any free
              products being offered by the brands being displayed on </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> may be
              out of stock. It is the responsibility of customer to check the same with the </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> team
              and confirm whether any such deals are being offered by </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school&nbsp;</span></a></span><span className="MsoHyperlink"><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>
              </span></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>to
              the customers. This is due to the fact that </span><span lang="EN-GB"><a target="_blank" href="https://teps.school"><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>https://teps.school</span></a></span><span lang="EN-GB" style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}> may
              not have the stock with which the promotional deal is being offered by the
              brand in question.</span></p>
      </div>
  )
}

export default TermsConditions