import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {  useNavigate, useParams } from "react-router-dom";
import DownArrow from "../asstes/icons/DownArrow.svg";
import LikeIcon from "../asstes/icons/Like.svg";
import LikedIcon from "../asstes/icons/Liked.svg";
import backArrow from "../asstes/icons/backArrow.svg";
import SaveIcon from "../asstes/icons/Save.svg";
import SavedIcon from "../asstes/icons/Saved.svg";
import UpArrow from "../asstes/icons/upArrow.svg";
import LikeByModal from "../Components/Modal/LikeByModal";
import { useAuth } from "../Context/AuthContext";
import { getMultitUser } from "../services/dashboardUsers";
import {
  deleteRating,
  getRatings,
  getTotalStrategyFav,
  getTotalStrategyLikes,
  postRating,
  postcomment,
  putRating,
  singleStratigys,
} from "../services/stratigyes";
import { generateChatGPTResponse } from "../services/chatGpt";
import { delLikes, getLikes, postLikes } from "../services/userLikes";
import { delSaves, getSaves, postSaves } from "../services/userSaves";
import editIcon from "../asstes/icons/editIcon.svg";
import "./styles/saveStratigy.css";
import { replaceNewlinesWithLineBreaks } from "../utils/utils";

import RatingModal from "../Components/Modal/RatingModal/RatingModal";
import styles from "./styles/SingleStr.module.css";
import LoadingCarousel from "../Components/LoadingCarousel/LoadingCarousel";
import MagicWond from "../Components/CommonSvgs/MagicWond";
import CopySvg from "../Components/CommonSvgs/CopySvg";
import toast, { Toaster } from "react-hot-toast";
import StrategyLikeList from "../Components/Modal/StrategyListModal/StrategyLikeList";
import StrategyFavList from "../Components/Modal/StrategyListModal/StrategyFavList";

const SingleStr = () => {
  const { user, seteditStrategyFormData, strategyNum,setselectedResource,isPlanExpired } = useAuth();
  const [str, setStr] = React.useState([]);
  const [comment, setComment] = React.useState([]);
  const [seeComment, setSeecomment] = React.useState(false);
  const [totalLikeUser, setTotalLikeUser] = React.useState([]);
  const { id } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isUsedStrategy, setisUsedStrategy] = useState(false);
  const [rating, setRating] = useState(0);
  const [userSaves, setUserSaves] = useState([]);
  const [saveUser, setSaveUser] = useState([]);
  const [show, setShow] = useState();
  const [userLikes, setUserLikes] = useState([]);
  const [totalUserLikes, setTotalUserLikes] = useState(0);
  const [isAlreadyRated, setisAlreadyRated] = useState(false);
  const [likeUser, setLikeUser] = useState([]);
  const [isLoadingContent, setIsLoadingContent] = useState(true);
  const [isFecthing, setisFecthing] = useState(false);
  const [teachinStratText, setTeachingStratText] = useState("");
  const [chatGptResponse, setChatGptResponse] = useState("");
  const [chatGptError, setChatGptError] = useState(false);
  const [commentInput, setCommentInput] = useState('');
  const [totalStrategyLikes, settotalStrategyLikes] = useState("")

  const [totalStrategySaves, settotalStrategySaves] = useState("")
  
  const [allLikedUser, setAllLikedUser] = useState("")
  const [allSavesUser, setAllSavesUser] = useState("")
  
  const [isLikeListModalOpen, setisLikeListModalOpen] = useState(false)
  const [isFavListModalOpen, setFavListListModalOpen] = useState(false)

  const navigate = useNavigate();
  const pRef = useRef(null);
  const [btnClicked,setBtnClicked] = useState(null);

  // Function to handle a star click
  const handleStarClick = (starIndex) => {
    setRating(starIndex);
    setisUsedStrategy(false);
    sendRating(starIndex);
    setisAlreadyRated(true);
  };
  React.useEffect(() => {
    singleStratigys(id).then((res) => {
      setStr(res[0]);
      setComment(res[1]?.comments);
    });
  }, [id]);

  const handleSeeComment = () => {
    if (seeComment) {
      setSeecomment(false);
    } else {
      setSeecomment(true);
    }
  };
  const handleBackClick = () => {
    window.history.go(-1);
  };
  const handleComment = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      strategie_id: id,
      user_name: `${user.firstName} ${user.lastName}`,
      comment: e.target.comment.value,
      postTime: new Date(),
    };
    postcomment(data).then((res) => {
      singleStratigys(id).then((res) => {
        setStr(res[0]);
        setComment(res[1]?.comments);
        e.target.reset();
        setIsLoading(false);
      });
    });
  };

  React.useEffect(() => {
    getLikes().then((res) => {
      const totalLike = res?.data?.filter((ress) => ress.strategie_id === id);
      setTotalUserLikes(totalLike.length);
      const userlike = totalLike?.filter((ress) => ress?.user_id === user._id);
      setLikeUser(userlike);
      setUserLikes(userlike?.map((ress) => ress?.strategie_id));
      getMultitUser(totalLike?.map((user_id) => user_id?.user_id)).then(
        (resUser) => setTotalLikeUser(resUser?.data)
      );
    });
  }, []);
  const handleApiLikes = (id) => {
    const data = {
      strategie_id: id,
      user_id: user._id,
    };
    postLikes(data).then((res) => {
      getLikes().then((res) => {
        const totalLike = res?.data?.filter((ress) => ress.strategie_id === id);
        setTotalUserLikes(totalLike.length);
        const userlike = totalLike?.filter((ress) => ress.user_id === user._id);
        setLikeUser(userlike);
        setUserLikes(userlike?.map((ress) => ress.strategie_id));
        getMultitUser(totalLike?.map((user_id) => user_id.user_id))
          .then((resUser) => setTotalLikeUser(resUser.data))
          .catch((err) => setTotalLikeUser([]));
      });
    });
  };
  const handleApiUnLikes = (id) => {
    if (likeUser.length !== 0) {
      delLikes(likeUser[0]._id).then((res) => {
        getLikes().then((res) => {
          const totalLike = res?.data?.filter(
            (ress) => ress.strategie_id === id
          );
          setTotalUserLikes(totalLike.length);
          const userlike = totalLike?.filter(
            (ress) => ress.user_id === user._id
          );
          setLikeUser(userlike);
          setUserLikes(userlike?.map((ress) => ress.strategie_id));
          getMultitUser(totalLike?.map((user_id) => user_id.user_id))
            .then((resUser) => setTotalLikeUser(resUser.data))
            .catch((err) => setTotalLikeUser([]));
        });
      });
    }
  };

  React.useEffect(() => {
    getSaves().then((res) => {
      const totalSave = res?.data?.filter((ress) => ress.strategie_id === id);
      const userlike = totalSave?.filter((ress) => ress.user_id === user._id);
      setSaveUser(userlike);
      setUserSaves(userlike?.map((ress) => ress.strategie_id));
    });
  }, []);
  const handleApiSaves = (id) => {
    const data = {
      strategie_id: id,
      user_id: user._id,
    };
    postSaves(data).then((res) => {
      getSaves().then((res) => {
        const totalSave = res?.data?.filter((ress) => ress.strategie_id === id);
        const userSave = totalSave?.filter((ress) => ress.user_id === user._id);
        setSaveUser(userSave);
        setUserSaves(userSave?.map((ress) => ress.strategie_id));
      });
    });
  };

  const handleApiUnSaves = (id) => {
    if (saveUser.length !== 0) {
      delSaves(saveUser[0]._id).then((res) => {
        if (res.data) {
          getSaves().then((res) => {
            const totalSave = res?.data?.filter(
              (ress) => ress.strategie_id === id
            );
            const userSave = totalSave?.filter(
              (ress) => ress.user_id === user._id
            );
            setSaveUser(userSave);
            setUserSaves(userSave?.map((ress) => ress.strategie_id));
          });
        }
      });
    }
  };
  const handleEditStrategy = async () => {
      if(isPlanExpired){
        toast.error("Subscription required")
        return
      }
      else{
        await seteditStrategyFormData(str);
        navigate(`/editStrategyform/${str._id}`);
      }
  };
  const handleUsedStrategy = () => {
    setisUsedStrategy(true);
  };
  const handleCloseRatingModal = () => {
    setisUsedStrategy(false);
  };
  const handleExplore = (resource) => {
    if(isPlanExpired){
      toast.error("Subscription required")
      return
    }else{
      setselectedResource(resource)
      navigate("/resources");
    }
  };
  const handleDeleteUsedStrategy = async () => {
    const dataToSend = {
      user_id: user._id,
      strategy_id: id,
    };
    try {
      const response = await deleteRating(dataToSend);
      if (response) {
        setisAlreadyRated(false);
      }
    } catch (error) {
      console.error("Error sending POST request:", error);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      const newText = replaceNewlinesWithLineBreaks(str["Teaching Strategy"]);
      if (pRef.current) {
        pRef.current.innerHTML = newText;
        setTeachingStratText(newText);
      }
      setIsLoadingContent(false); // Mark loading as complete
    }, 100);
  }, [str["Teaching Strategy"]]);
  const sendRating = async (starIndex) => {
    const dataToSend = {
      rating: starIndex,
      user_id: user._id,
      strategy_id: id,
    };
    try {
      if (isAlreadyRated) {
        const response = await putRating(dataToSend);
      } else {
        const response = await postRating(dataToSend);
      }
    } catch (error) {
      console.error("Error sending POST request:", error);
    }
  };
  useEffect(() => {
    getRatings(id).then((res) => {
      const filteredData = res.filter(
        (obj) => obj.user_id === user._id && obj.strategy_id === id
      );
      if (filteredData.length) {
        setisAlreadyRated(true);
      }
    });
  }, [isAlreadyRated]);

  const callChatGPTApi = (promptStart) => {
    setisFecthing(true);
    setChatGptError(false);
    generateChatGPTResponse({
      prompt: `${promptStart}`,
    })
      .then((res) => {
        setisFecthing(false);
        setChatGptResponse(res?.data?.response);
        console.log({ res });
      })
      .catch((err) => {
        setisFecthing(false);
        setChatGptResponse("");
        setChatGptError(true);
        console.log({ err });
      });
  };

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(chatGptResponse)
      .then(() => {
        toast.success("Data Copied Successfully");
      })
      .catch((err) => {
        toast.error("Some error occured during copying");
        console.error("Unable to copy text to clipboard:", err);
      });
  };
  const gptpRef = useRef(null); 
 useEffect(() => {
      const newText = replaceNewlinesWithLineBreaks(chatGptResponse);
      if (gptpRef.current) {
        gptpRef.current.innerHTML = newText;
      }
  }, [chatGptResponse]);




useEffect(() => {
// get total like
getTotalStrategyLikes(id).then((res)=>{
  console.log("Likeres",res)
  settotalStrategyLikes(res?.users?.length)
  setAllLikedUser(res?.users)
})

}, [id,userLikes])

useEffect(() => {
// get total Saves

  getTotalStrategyFav(id).then((res)=>{
    console.log("Favres",res)
    settotalStrategySaves(res?.users?.length)
    setAllSavesUser(res?.users)
  })
}, [id,userSaves])


  return (
    <div>
      {isFecthing ? <LoadingCarousel /> : null}
      <LikeByModal
        show={show}
        handleClose={() => setShow(false)}
        totalReact={totalLikeUser}
      />
      <StrategyLikeList 
       show={isLikeListModalOpen}
       handleClose={() => setisLikeListModalOpen(false)}
      allLikedUser={allLikedUser}
      />
     <StrategyFavList 
       show={isFavListModalOpen}
       handleClose={() => setFavListListModalOpen(false)}
       allSavesUser={allSavesUser}
      />
      <div className=" d-flex justify-content-center align-items-center mb-1 position-relative strPageLine">
        <button className="backbutton" onClick={handleBackClick}>
          <img src={backArrow} alt="backArrow" className="mb-md-1" />
          {`${t("Back")}`}
        </button>
        <hr className="line" />
        <p className="headText d-none d-md-block text-center">
          {t("Strategy")}
        </p>
        <hr className="line" />
      </div>

      <div className="mx-2 mx-md-4">
        <p className="single_str_head">
          {str?.Grade} &gt; {str?.Subject} &gt; {str?.["Super Topic"]} &gt;{" "}
          {str?.Topic} &gt; {str[`Sub Topic`]} &gt; {str["Sub-sub topic"]}
        </p>
      </div>

      <div className="mx-2 mx-md-4">
        <div className="card_pad">
          <div className="my-4">
            <div className="d-flex justify-content-between my-4 flex-column">
              <p className="savestr_head mt-0">
                {t("Learning Outcome")}:{" "}
                <span className="learningOutcome">
                  {str["Learning Outcome"]}
                </span>
              </p>

              <div className="col-9  w-100 textContainer p-2 p-md-4">
                <div className="me-1">
                  <div>
                    <div className=" mb-md-1 str_title">
                      <p className="str_name d-flex">
                        {t("strategy")}{" "}
                        {strategyNum != "" ? (
                          <span className="counter_str">{`${strategyNum}`}</span>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                    {str["Pedagogical Approach"] && (
                      <div className="mb-md-1 ">
                        <i className="pedalogicalText">
                          {str["Pedagogical Approach"].toUpperCase()}
                        </i>
                      </div>
                    )}
                  </div>
                </div>
                {isLoadingContent ? (
                  "Loading..."
                ) : (
                  <p
                    ref={pRef}
                    className="newLine me-2 me-md-2 disableCopy"
                  ></p>
                )}

                <div className="d-flex justify-content-between my-2">
                  <div className="d-flex gap-2 gap-md-3 align-items-start">
                    <div className="d-flex align-items-center flex-column">
                      <div className="saveIconDiv">
                        {userSaves?.includes(str?._id) ? (
                          <img
                            onClick={() => handleApiUnSaves(str?._id)}
                            className="save_like"
                            src={SavedIcon}
                            alt="SavedIcon"
                          />
                        ) : (
                          <img
                            onClick={() => handleApiSaves(str?._id)}
                            className="save_like"
                            src={SaveIcon}
                            alt="SavedIcon"
                          />
                        )}
                      </div>
                      <p className="saveTotalCount" onClick={()=>{
                        if(allSavesUser?.length>0){
                          setFavListListModalOpen(true)
                        }
                        }}>{totalStrategySaves}</p>
                    </div>

                    <div className="d-flex align-items-center flex-column">
                      <div className="likeIconDiv">
                        {userLikes.includes(str?._id) ? (
                          <img
                            onClick={() => handleApiUnLikes(str?._id)}
                            className="save_likes"
                            src={LikedIcon}
                            alt="LikedIcon"
                          />
                        ) : (
                          <img
                            onClick={() => handleApiLikes(str?._id)}
                            className="save_likes"
                            src={LikeIcon}
                            alt="LikedIcon"
                          />
                        )}
                      </div>
                      <p className="likeTotalCount" onClick={()=>{
                        if(allLikedUser?.length>0){
                          setisLikeListModalOpen(true)
                        }
                        }
                        }>{totalStrategyLikes}</p>
                    </div>

                    {!isAlreadyRated ? (
                      <button
                        className="secondaryButton"
                        onClick={handleUsedStrategy}
                      >
                        {t("Mark as used")}
                      </button>
                    ) : (
                      <button
                        className="primaryButton"
                        onClick={handleDeleteUsedStrategy}
                      >
                        {t("I used this!")}
                      </button>
                    )}
                  </div>
                  <div className=" d-flex gap-3 justify-content-center">
                    <button
                      className="secondaryButton"
                      onClick={handleEditStrategy}
                    >
                      {t("Edit Strategy")}{" "}
                      <img src={editIcon} alt="edit" className="mx-md-2" />
                    </button>
                  </div>
                </div>
                <RatingModal
                  show={isUsedStrategy}
                  handleClose={handleCloseRatingModal}
                  handleStarClick={handleStarClick}
                  rating={rating}
                  setRating={setRating}
                />
                <div className={styles.exploreTexts}>
                  {str?.Grade == "Pre-K" ||
                  str?.Grade == "UKG" ||
                  str?.Grade == "LKG" ? (
                    <p
                    onClick={() =>
                       navigate("/resources")
                    }
                    >Explore more about foundational learning...</p>
                  ) : str["Pedagogical Approach"]?.toLowerCase() == "constructivism" ||
                    str["Pedagogical Approach"]?.toLowerCase() === "inquiry-based learning" ||
                    str["Pedagogical Approach"]?.toLowerCase() === "project-based learning" ? (
                    <p
                      onClick={() =>
                        handleExplore(str?.["Pedagogical Approach"]?.toLowerCase())
                      }
                    >
                      Explore more about {str?.["Pedagogical Approach"]}...
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className={styles.chatGPTbox}>
                  <div className={styles.gptButtonsContainer}>
                    <div className={styles.magicIconBoxDesktop}>
                      <div>
                        <MagicWond mobile={false} />
                      </div>
                    </div>
                    <button
                      className={btnClicked===1?styles.clicked:""}
                      onClick={() => {
                        setBtnClicked(1);
                        callChatGPTApi(
                          `You will be given the grade, learning outcome and a teaching strategy. 
                          Provide a numbered list of prior knowledge needed by students, before the teaching strategy 
                          begins, in order to meet the learning outcome. Keep these points in mind while creating this list:
                          1. The prior knowledge should be about conceptual understanding or the academic skill 
                          connected to the learning outcome.
                          2. The prior knowledge should be grade appropriate.
                          3. Explain the concept or skill in a short sentence.
                          4. Each point or prior knowledge must be accompanied by a specific question that the teacher 
                          can ask students to assess if they possess this knowledge
                          Grade:  ${str?.Grade}
                          Learning outcome:  ${str["Learning Outcome"]}
                          Teaching strategy:  ${str["Teaching Strategy"]}
                          `
                        );
                      }}
                    >
                      <MagicWond mobile={true} /> Prior knowledge
                    </button>
                    <button
                      className={btnClicked===2?styles.clicked:""}
                      onClick={() => {
                        setBtnClicked(2);
                        callChatGPTApi(
                          `You will be given a learning outcome and grade based on which you will need to identify 2-3 
                          potential misconceptions that students may have. 
                          A misconception is a conceptual misunderstanding and not simple errors that students can make 
                          accidently. So please keep that in mind while suggesting misconceptions.
                          Points to consider in your response: 
                          1. Grade level
                          2. The learning outcome
                          3. Give one major potential misconception a student may have.
                          4. Provide specific ideas for a teacher to identify the misconception. 
                          5. Give a quick tip to help the teacher remove the misconception from students
                          Grade:  ${str?.Grade}
                          Learning outcome:  ${str["Learning Outcome"]}
                          `
                        );
                      }}
                    >
                      <MagicWond mobile={true} /> Misconceptions
                    </button>
                    <button
                      className={btnClicked===3?styles.clicked:""}
                      onClick={() => {
                        setBtnClicked(3);
                        callChatGPTApi(
                          `You will be given the grade, learning outcome and a teaching strategy.
                          Based on these, create 3 questions to check for students’ understanding after the teaching 
                          strategy has been used. 
                          Keep these points in mind while creating these questions:
                          1. The questions must be multiple-choice questions.
                          2. Give 4 answer options to choose from. Only 1 answer option should be correct, and the other 3
                          answer options should be incorrect.
                          3. As far as possible, each incorrect answer must reveal a misconception that the student has. 
                          4. At the end, give the answer key, which should include the correct answer option and an 
                          explanation how the incorrect answer options reveal a misconception
                          Teaching strategy:  ${str["Teaching Strategy"]}
                          Grade:  ${str?.Grade}
                          Learning outcome:  ${str["Learning Outcome"]}
                          `
                        );
                      }}
                    >
                      <MagicWond mobile={true} /> Check for understanding
                    </button>
                    <button
                      className={btnClicked===4?styles.clicked:""}
                      onClick={() => {
                        setBtnClicked(4);
                        callChatGPTApi(
                          `You will be given: 
                          Teaching strategy:  ${str["Teaching Strategy"]} 
                          Pedagogical approach:  ${str["Pedagogical Approach"]}
                          Grade:  ${str?.Grade}
                          Learning outcome:  ${str["Learning Outcome"]}
                          Convert the teaching strategy into a lesson plan. Keep in mind the following while creating the 
                          lesson plan:
                          1. Start the lesson plan with a list of specific materials needed, a list of worksheets needed, and 
                          the learning outcome. 
                          2. Mention the key terms relevant to the content knowledge mentioned in the learning outcome.
                          3. Choose and mention any two Developmental Domains: Fine-Motor/Gross-motor, Language/ 
                          Communication, Cognitive/Sensory, Social-emotional, and Aesthetic/ Creativity, which are 
                          aligned with the strategy. 
                          4. Pick any one, specific (not already mentioned in the strategy) prior knowledge that the 
                          students may have and make a connection of this prior knowledge to academic knowledge using 
                          a specific example.
                          5. Make a specific age-appropriate connection of academic knowledge to students' day-to-day 
                          examples.
                          6. Take each step of the teaching strategy and elaborate on them after breaking each step down 
                          into numbered steps.
                          7. Take each numbered step and elaborate on what is already given in the strategy with step-by-
                          step instructions for the teacher as to what they must say, ask, and do in order to facilitate the 
                          lesson.  Use direct speech for this, starting with verbs like Say: ... or Ask: ... or so on. 
                          7.1. ONLY when a question is asked, then write instructions for the teacher as to what they must 
                          ask by including specific follow-up questions. After asking what is given in the strategy, 
                          elaborate on them by suggesting specific ways to moderate. Then include how the discussion 
                          will happen while students are sharing responses. Here are some examples: Take 2-3 responses, 
                          write keywords from students' responses on the board, write the specific follow-up questions 
                          they can ask if the student’s answer is incorrect, and specific examples of questions to ask to 
                          push students to think critically. In case of explanation and discussion, elaborate on what the 
                          teacher needs to do. You can use many more specific, innovative ways of classroom 
                          management.
                          7.2. ONLY when there is an explanation, write what exactly the teacher needs to say to explain. 
                          Followed by what to do to support the explanation. Here are some examples: diagram, concept 
                          map, etc.
                          7.3. ONLY when there is a demonstration, write exactly what the teacher needs to do. Followed 
                          by what to do step-by-step.
                          8. At the end of each step, conclude by writing in simple language exactly what the teacher has 
                          to say to explain the knowledge that students have learned till that step.
                          9. Keep in mind that you are creating this lesson plan for a novice teacher who is looking to 
                          understand how the teaching will look in the classroom. So, provide more specifics. Here is one 
                          example, when you say summarise, write what to say to summarise.
                          10. Ensure that you clearly mention where and how the specific materials and the worksheet 
                          recommended have to be used in the lesson.
                          11. Write three clear sentences for an academic explanation of the main concept or skill, along 
                          with any rules. This is to build teachers' academic knowledge. Write it in a single paragraph. 
                          12. Give specific and meaningful homework or home assignments for students.
                          13. Include extensions for quick learners that give one specific example in the form of a grade-
                          appropriate challenging problem or concept that quick learners should be able to explore.
                          14. Include adaptations and accommodations that the teacher can make extra for struggling 
                          learners in the form of a simple activity or task. 
                          15. Mention the technology needed for the lesson plan, if applicable`
                        );
                      }}
                    >
                      <MagicWond mobile={true} /> Lesson Plan
                    </button>
                  </div>
                  {chatGptResponse.length !== 0 || chatGptError === true ? (
                    <div className={styles.gptAnswer}>
                      {chatGptResponse.length !== 0 && (
                        <div className={styles.copyContainer}>
                          <CopySvg onClick={handleCopyClick} />
                        </div>
                      )}
                      {chatGptResponse.length !== 0 && <p ref={gptpRef}>{chatGptResponse}</p>}
                      {chatGptError === true ? (
                        <p className={styles.gptError}>
                          We are experiencing difficulties while loading the
                          data.
                          <br /> Please try again.
                        </p>
                      ) : null}
                      {chatGptResponse.length !== 0 && (
                        <div className={styles.copyContainer}>
                          <CopySvg onClick={handleCopyClick} />
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
                {/* ================ FOR LARGE SCREEN =============  */}
                <div className="largeCommentContainer">
                  <div className="comment_div d-none d-md-block">
                    <form onSubmit={handleComment}>
                      <div>
                        <input
                          required
                          name="comment"
                          placeholder={`${t("Add a comment")}...`}
                          className="w-100 comment_input"
                          type="text"
                          onChange={(e) => setCommentInput(e.target.value)}
                        />
                      </div>
                      <div className="d-flex justify-content-end comment_submit">
                        <input
                          disabled={isLoading|| commentInput === ''}
                          type="submit"
                          value={`${t("Submit")}`}
                        />
                      </div>
                    </form>
                    <div className={!seeComment ? "d-block" : "d-none"}>
                      <div
                        onClick={handleSeeComment}
                        className="text-center see_comment"
                      >
                        <p className="m-0">
                          {t("View comments")} {comment?.length}{" "}
                          <img width="10px" src={DownArrow} alt="DownArrow" />
                        </p>
                      </div>
                    </div>
                    <div className={seeComment ? "d-block" : "d-none"}>
                      <div
                        onClick={handleSeeComment}
                        className="text-center see_comment"
                      >
                        <p className="m-0">
                          {t("Hide comments")} {comment?.length}{" "}
                          <img width="10px" src={UpArrow} alt="DownArrow" />
                        </p>
                      </div>
                      {comment?.map((res, index) => (
                        <div key={index} className="mt-4">
                          <p className="comment_head">
                            {res.user_name}{" "}
                            <span className="comment_span">
                              {moment(res.postTime)
                                .startOf("MMMM Do YYYY, h:mm:ss a")
                                .fromNow()}
                            </span>
                          </p>
                          <p className="comment_text">{res.comment}</p>
                          <hr />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* ============ FOR SMALL SCREEN ================  */}
                <div className="comment_div d-block d-md-none">
                  <p className="comment_div_p">Comments</p>
                  <form onSubmit={handleComment}>
                    <div>
                      <input
                        required
                        name="comment"
                        placeholder="Add a comment..."
                        className="w-100 comment_input"
                        type="text"
                        onChange={(e) => setCommentInput(e.target.value)}
                      />
                    </div>
                    <div className="d-flex justify-content-end comment_submit">
                      <input type="submit" disabled={isLoading|| commentInput === ''}/>
                    </div>
                  </form>
                  <div className={!seeComment ? "d-block" : "d-none"}>
                    <div
                      onClick={handleSeeComment}
                      className="text-center see_comment"
                    >
                      <p className="m-0">
                        View comments {comment?.length}{" "}
                        <img
                          width="7px"
                          height="5px"
                          src={DownArrow}
                          alt="DownArrow"
                        />
                      </p>
                    </div>
                  </div>
                  <div className={seeComment ? "d-block" : "d-none"}>
                    <div
                      onClick={handleSeeComment}
                      className="text-center see_comment"
                    >
                      <p className="m-0">
                        Hide comments {comment?.length}{" "}
                        <img
                          width="7px"
                          height="5px"
                          src={UpArrow}
                          alt="DownArrow"
                        />
                      </p>
                    </div>
                    {comment?.map((res, index) => (
                      <div key={index} className="mt-4">
                        <p className="comment_head">
                          {res.user_name}{" "}
                          <span className="comment_span">
                            {moment(res.postTime)
                              .startOf("MMMM Do YYYY, h:mm:ss a")
                              .fromNow()}
                          </span>
                        </p>
                        <p className="comment_text">{res.comment}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-right" />
    </div>
  );
};

export default SingleStr;
