import React from 'react';
import Profile from '../Components/Profile/Profile';

const Profiles = () => {
  return (
    <>
      <Profile />

    </>
  );
};

export default Profiles;