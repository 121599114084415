import React from 'react'

const Uparrow = ({rotate,fill}) => {
  return (
    <svg style={{rotate:rotate===true?"180deg":""}} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill={"none"}>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.64659 4.14652C5.74035 4.05278 5.86751 4.00012 6.00009 4.00012C6.13267 4.00012 6.25983 4.05278 6.35359 4.14652L9.18209 6.97501C9.27317 7.06932 9.32357 7.19562 9.32243 7.32672C9.32129 7.45781 9.2687 7.58322 9.176 7.67592C9.08329 7.76863 8.95789 7.82121 8.82679 7.82235C8.69569 7.82349 8.56939 7.77309 8.47509 7.68202L6.00009 5.20701L3.52509 7.68202C3.43079 7.77309 3.30449 7.82349 3.17339 7.82235C3.04229 7.82121 2.91688 7.76863 2.82418 7.67592C2.73148 7.58322 2.67889 7.45781 2.67775 7.32672C2.67661 7.19562 2.72701 7.06932 2.81809 6.97501L5.64659 4.14652Z" rotate={rotate===true?180:""} fill={fill??"#1AA05B"}/>
</svg>
  )
}

export default Uparrow